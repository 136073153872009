import React from 'react';
import { 
  List, 
  Datagrid, 
  TextField, 
  ReferenceField, 
  Pagination,
  Create, 
  TopToolbar,
  CreateButton,
  ExportButton,
  useListContext,
  useDataProvider,
  fetchRelatedRecords
} from 'react-admin';
import { ImportButton } from "react-admin-import-csv";

const CriteriaList : React.FC<{}>= props => {
  const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50, 100]} {...props} />;

  const ListActions = (props: any) => {
    const {
      exporter
    } = useListContext(props);
    const dataProvider = useDataProvider();

    return (
    <TopToolbar>
      <CreateButton {...props} />
      <ExportButton 
        exporter={(data) => {
          const dataMaped = data.map(({key, value, lang}: any) => ({key, value, lang}));
          exporter &&
          exporter(
            dataMaped,
            fetchRelatedRecords(dataProvider),
            dataProvider,
            'criterias'
        )
        }} 
        maxResults={10000} 
        {...props} 
      />
      <ImportButton resource="criterias" />
    </TopToolbar>
  )}

  return (
    <List
      {...props}
      pagination={<PostPagination />}
      actions={<ListActions />}
    >
      <Datagrid rowClick="edit">
        <TextField  source="key" label='criteria.key' />
        <TextField  source="value" label='criteria.value' />
        <TextField  source="lang" label='criteria.lang' />
      </Datagrid>
    </List>
  );
}

export default CriteriaList;
