import React, {  } from 'react';
import { Edit, SimpleForm } from 'react-admin';

import FaqForm from './FaqForm';

const FaqEdit : React.FC<{}>= props =>
  <Edit {...props} redirect={() => 'faq'} resource='post' >
    <FaqForm />
  </Edit>

export default FaqEdit;