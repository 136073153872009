import React from 'react';
import { Admin, Resource } from 'react-admin';
import { useStoreContext } from 'react-admin';

import history from './history';

import CustomLayout from './layout/Layout';
import CustomLogin  from './layout/Login';


import dataProvider from './providers/dataprovider';
import authProvider from './providers/authprovider';
import i18nProvider from './providers/i18nprovider';

import theme from './shared/styles/theme';

import { Route, Navigate } from 'react-router';
import { CustomRoutes } from 'react-admin';
import { resourcesList } from './resources';
import canI from './providers/cani';

import { FIRST_CONNECTION, USER_SETTINGS } from './shared/constants/routes';
import FirstConnections from './components/user/FirstConnection';
import Settings from './components/user/Settings';

function App() {
  const store = useStoreContext();
  const account = store.getItem('Account') || { roles : []};
  const canIResource = canI(account);

  return (
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        layout={CustomLayout}
        theme={theme}
        loginPage={CustomLogin}
        history={history}
        disableTelemetry
      >
        {
          resourcesList.map(
            ({name, list, create, edit, options}) => {
              // if (!canIResource('read', name)) {
              //   return null;
              // }
              const props = {
                key : name,
                name,
                list   : list,
                create : create,
                edit   : edit,
                options,
              };

              return <Resource {...props} />;
            }
          )
        }
        <CustomRoutes noLayout>
          <Route path={FIRST_CONNECTION} element={<FirstConnections />} />
        </CustomRoutes>
        <CustomRoutes>
            <Route path={USER_SETTINGS} element={<Settings />} />
        </CustomRoutes>
      </Admin>
  );
}

export default App;
