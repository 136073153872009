import { log } from 'console';
import React from 'react';
import { 
  List, 
  Datagrid, 
  TextField, 
  ReferenceField, 
  Pagination, 
  TopToolbar,
  SortButton,
  CreateButton,
  ExportButton,
  FilterButton,
  TextInput,
  FilterLiveSearch,
  FormDataConsumer,
  ReferenceManyCount,
  DateField
} from 'react-admin';

const PostFilters = () => 
  <TextInput label="Search" source="q" alwaysOn />

const ListActions = () => (
  <TopToolbar>
    <FilterLiveSearch source="q" />
    <SortButton fields={['createdAt']} label="Trier par date de création" />
    {/* <CreateButton /> */}
    <ExportButton />
  </TopToolbar>
);


const RefugeList : React.FC<{}>= props => {
  const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50, 100]} {...props} />;
  return (
    <List
      {...props}
      bulkActionButtons={false}
      pagination={<PostPagination />}
      actions={<ListActions />}
      /* filters={<PostFilters {...props} />} */
    >
      <Datagrid rowClick="edit">
        <TextField source='name' label='refuge.name' />
        <TextField source='email' label='refuge.email' />
        {/* <TextField source='phone' label='refuge.phone' />
        <TextField source='siret' label='refuge.siret' />
        <TextField source='rna_hk' label='refuge.rna_hk' /> */}
        <TextField source='address' label='refuge.address' />
        <ReferenceManyCount
          source='id'
          label="refuge.animals"
          reference="animal"
          target="refugeId"
          filter={{adopted : false, sponsorShip : false}}
          sortable={false}
        />
        <DateField source='createdAt' label='refuge.createdAt' />
      </Datagrid>
    </List>
  );
}

export default RefugeList;