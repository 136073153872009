import React from 'react';
import { List, Datagrid, TextField, ExportButton, CreateButton, TopToolbar, fetchRelatedRecords, useDataProvider, useListContext } from 'react-admin';
import { ImportButton } from "react-admin-import-csv";

const ListActions = (props: any) => {
  const {
    exporter
  } = useListContext(props);
  const dataProvider = useDataProvider();

  return (
  <TopToolbar>
    <CreateButton {...props} />
    <ExportButton 
      exporter={(data) => {
        const dataMaped = data.map(({key, value, lang}: any) => ({key, value, lang}));
        exporter &&
        exporter(
          dataMaped,
          fetchRelatedRecords(dataProvider),
          dataProvider,
          'color'
      )
      }} 
      maxResults={10000} 
      {...props} 
    />
    <ImportButton resource="color" />
  </TopToolbar>
)}

const ColorsList : React.FC<{}>= props => {
  return (
    <List
      {...props}
      actions={<ListActions />}
    >
      <Datagrid rowClick="edit">
        <TextField  source="key" label='animalType.key' />
        <TextField  source="value" label='animalType.value' />
        <TextField  source="lang" label='animalType.lang' />
      </Datagrid>
    </List>
  );
}

export default ColorsList;
