
import React, {  } from 'react';
import { Create, SimpleForm, TextInput, ImageInput, ImageField } from 'react-admin';

const BlogPictureCreate : React.FC<{}>= props =>
  <Create {...props} redirect='list'>
    <SimpleForm>
      <ImageInput source="photo" label=" " accept="image/*" sx={{width : 300}} >
        <ImageField source="src" title="title" sx={{ img : {width : '250px !important', height : '250px !important'}}} />
      </ImageInput>
    </SimpleForm>
  </Create>

export default BlogPictureCreate;