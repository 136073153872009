import React, { Fragment, useState } from 'react';

import { RaRecord } from 'ra-core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Box } from '@material-ui/core';
import { ImageButtons } from './ImageButton';

const BlogImageDialog : React.FC<BlogImageDialogProps> = (props) => {
  const {
    Layout,
    ariaLabel = '',
    title = '',
  } = props;

  const [visible, setVisible] = useState(false);

  const handleShow = () => setVisible(true);
  const handleHide = () => setVisible(false);

  return (
    <Fragment>
      <ImageButtons onClick={handleShow} />
      <Dialog
        fullWidth
        maxWidth='lg'
        open={visible}
        onClose={handleHide}
        aria-label={ariaLabel || title}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box display={'flex'} flexDirection='column' sx={{padding : '20px 50px 0 50px'}}>
            <Layout handleHide={handleHide} />
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export interface BlogImageDialogProps {
  Layout : React.FC<any>;
  onChange? : (data : any) => void;
  ariaLabel? : string;
  title : string;
  target? : string;
  label? : string;
  transform? : (data : RaRecord) => RaRecord;
  record : RaRecord
}

export default BlogImageDialog;
