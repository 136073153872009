import React, {  } from 'react';
import { Create, SimpleForm } from 'react-admin';

import CriteriaForm from './CriteriaForm';

const CriteriaCreate : React.FC<{}>= props =>
  <Create {...props} redirect='list' >
    <SimpleForm warnWhenUnsavedChanges>
      <CriteriaForm />
    </SimpleForm>
  </Create>

export default CriteriaCreate;