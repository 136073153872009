import * as React from 'react';
import { ToggleButton, ToggleButtonProps } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { useTiptapEditor } from 'ra-input-rich-text';
import { useTranslate } from 'react-admin';

export const ImageButtons = (props: Omit<ToggleButtonProps, 'value'>) => {
    const translate = useTranslate();
    const editor = useTiptapEditor();

    const label = translate('ra.tiptap.image', { _: 'Image' });

    return editor ? (
        <ToggleButton
            aria-label={label}
            title={label}
            {...props}
            disabled={!editor?.isEditable}
            value="image"
            onClick={props.onClick}
        >
            <ImageIcon fontSize="inherit" />
        </ToggleButton>
    ) : null;
};