import { DataProvider } from 'ra-core';

import config from './../configprovider';
import createDefaultDataProvider from './default';
import uploadsDataProvider from './uploads';

export const modelDataProvider : DataProvider = createDefaultDataProvider(config('MODEL_API_URL'), 30);

export const breedDataProvider : DataProvider = {
  ...modelDataProvider,
  create : async (resource, params) => {
    const { rawFile, animalType, ...record } = params.data;
   if(rawFile){
     return uploadsDataProvider.create('upload-breed', {
        data : {
          __file__ : {rawFile},
          animalType
        }
      });
    } else {
      return modelDataProvider.create('breed', params);
    }
   } 
}