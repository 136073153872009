import React, {  } from 'react';
import { Edit, SimpleForm } from 'react-admin';

import UserForm from './UserForm';

const UserEdit : React.FC<{}> = props =>
  <Edit mutationMode='undoable' {...props} resource='user' redirect='/association'>
    <UserForm />
  </Edit>;

export default UserEdit;
