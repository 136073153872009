import { CreateParams, UpdateParams, DataProvider } from 'ra-core';
import _ from 'lodash';
import config from '../configprovider';
import createDefaultDataProvider from './default';
import uploadsPicturesProvider from './uploadsPictures';
import { fetchUtils } from 'react-admin';
import flatten from 'flat';
import { stringify } from 'query-string';
import { Photo } from '../../types';


const httpClient = fetchUtils.fetchJson;

export const modelDataProvider : DataProvider = createDefaultDataProvider(config('UPLOAD_API_URL'), 30);

const queryFilterArray = (query : string) : string =>
  query?.replace(/f\.([a-zA-Z]+)\.(gt|ge|lt|le)=/g, 'f=$1;$2;');

export const createBlogPicturesDataProvider = (apiUrl : string, ttl = 0) : DataProvider => ({
  ...modelDataProvider,
  getOne : async (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, { user: { authenticated: true, token : `Bearer ${localStorage.getItem('token')}` }}).then(({ json } : any) => {
      return {
        data: {
          ...json, 
          main : json.main ? {id : json.main, src : `${config('UPLOAD_API_URL')}/blog-pictures/${json.main}/binary/medium`} : null,
          thumbnail : json.thumbnail ? {id : json.thumbnail, src : `${config('UPLOAD_API_URL')}/blog-pictures/${json.thumbnail}/binary/medium`} : null
        },
        ...(ttl ? { validUntil : new Date(Date.now() + ttl*1000) } : {})
      }
    })
  },
  getList : async (resource, params) => {
    const { page, perPage } = params.pagination || {};
    const { field, order }  = params.sort || {};
    const filter            = params.filter || {};
  
    const query = {
      ...flatten<typeof filter, typeof filter>(filter),
      sort: [[field, order]],
      page: JSON.stringify(page - 1),
      pageSize: JSON.stringify(perPage),
    };
    const url = `${apiUrl}/${resource}?${queryFilterArray(stringify(query))}`;;
    
    return httpClient(url, { user: { authenticated: true, token : `Bearer ${localStorage.getItem('token')}` }}).then(({ json } : any) => {
      const data = json.data.map((d: Photo) => ({
        ...d,
        photo : {
          src : `${config('UPLOAD_API_URL')}/${resource}/${d.id}/binary/medium`,
          id : d.id
        }
      }))
      return {
        data: [...data],
        total: json.pagination.itemsCount,
        ...(ttl ? { validUntil : new Date(Date.now() + ttl*1000) } : {})
      }
    });
  },
  create : async (resource, params) => {
    let newParams: CreateParams<any>;

    newParams = {
      data : {
        __file__ : params.data.photo
      }
    }
    return await uploadsPicturesProvider.create('blog-pictures', newParams);
  },
})