import React, {  } from 'react';
import { Create, SimpleForm } from 'react-admin';

import PartnerForm from './PartnerForm';

const PartnerCreate : React.FC<{}>= props =>
  <Create {...props} redirect={() => 'partner'} resource='post' >
    <PartnerForm />
  </Create>

export default PartnerCreate;