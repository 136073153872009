import { ROOT, ADMIN } from '../shared/constants/roles';
import { resourceNames } from '../resources';

const PERMISSIONS : {
  [resource : string] : {
    [action : string] : {
      [role : string] : boolean
    }
  }
} = {};

[...resourceNames, 'finances-dashboard'].forEach(
  (resource : string) => {
    PERMISSIONS[resource] = {
      'read'   : {[ROOT] : true, [ADMIN] : true},
      'list'   : {[ROOT] : true, [ADMIN] : true},
      'create' : {[ROOT] : true, [ADMIN] : true},
      'edit'   : {[ROOT] : true, [ADMIN] : true},
      'delete' : {[ROOT] : true, [ADMIN] : true}
    }
  }
);


const addRoleToPermissions = (resource: string, actions : string[] | string, role : string) => {
  (Array.isArray(actions) ? actions : [actions])
  .forEach(action => {
    PERMISSIONS[resource][action][role] = true
  })
};

// addRoleToPermissions('resource-1', ['read', 'list', 'create', 'edit'], ROLE1);
// addRoleToPermissions('resource-2', ['read'], ROLE2);

const canI =
  (account : {roles : string[]}) =>
  (action : string, resource: string) : boolean => {

    const { roles = [] } = account;
    const permittedRoles = PERMISSIONS[resource]?.[action] || {};

    return roles.some(role => !!permittedRoles[role])
}

export default canI;