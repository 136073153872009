import { DataProvider } from 'ra-core';

import {
  modelDataProvider,
} from './services/model';
import {accountsDataProvider} from './services/accounts';
import { breedDataProvider } from './services/breed';
import { createAnimalDataProvider } from './services/animal';
import configProvider from './configprovider';
import { createPostDataProvider } from './services/post';
import { createBlogPicturesDataProvider } from './services/blog-pictures';
import { UserDataProvider } from './services/user';
import { RefugeDataProvider } from './services/refuge';

const services : {[key : string] : DataProvider} = {
  'accounts'      : accountsDataProvider,
  'breed'         : breedDataProvider,
  'animal'        : createAnimalDataProvider(configProvider('MODEL_API_URL'), 30),
  'post'          : createPostDataProvider(configProvider('MODEL_API_URL'), 30),
  'blog-pictures' : createBlogPicturesDataProvider(configProvider('UPLOAD_API_URL'), 30),
  'user'          : UserDataProvider,
  'refuge'        : RefugeDataProvider,
};

const getServiceDataProvider = (resource : string) : DataProvider => services[resource] || modelDataProvider;

const dataProvider : DataProvider = {
  getList : async (resource, params) =>
    getServiceDataProvider(resource).getList(resource, params),

  getOne : async (resource, params) =>
    getServiceDataProvider(resource).getOne(resource, params),

  getMany : async (resource, params) =>
    getServiceDataProvider(resource).getMany(resource, params),

  getManyReference: async (resource, params) =>
    getServiceDataProvider(resource).getManyReference(resource, params),

  create : async (resource, params) =>
    getServiceDataProvider(resource).create(resource, params),

  update : async (resource, params) =>
    getServiceDataProvider(resource).update(resource, params),

  updateMany : async (resource, params) =>
    getServiceDataProvider(resource).updateMany(resource, params),

  delete : async (resource, params) =>
    getServiceDataProvider(resource).delete(resource, params),

  deleteMany : async (resource, params) =>
    getServiceDataProvider(resource).deleteMany(resource, params),
}


export default dataProvider;
