export const serialize = (obj : any, prefix : any): any => {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push((v !== null && typeof v === "object") ?
        serialize(v, k) :
        encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  }
  return str.join("&");
}

export const langChoices = [{id : 'fr', name : 'Fr'}, {id : 'de', name : 'De'}, {id : 'es', name : 'Es'}, {id : 'en', name : 'En'}];
export const ageChoices: any[] = [{id : 'young', name : 'Jeune'}, {id : 'adult', name : 'Adulte'}, {id : 'senior', name : 'Senior'}];
export const genreChoices: any[] = [{id : 'male', name : 'Mâle'}, {id : 'female', name : 'Femelle'}];
export const sizeChoices: any[] = [{id : 'small', name : 'Petite taille'}, {id : 'medium', name : 'Moyenne taille'}, {id : 'big', name : 'Grande taille'}];
export const categories: any[] = [
  {id : 'conseils-et-vivre-ensemble', name : 'Conseils et vivre-ensemble'},
  {id : 'paawrtrait', name : "Paaw'trait"}, 
  {id : 'word-wild', name : 'Word wild'},
  {id : 'actualites', name : 'Actualités'}
];