import _ from "lodash";
import React from "react";
import {
	Datagrid,
	ExportButton,
	List,
	TextField,
	TopToolbar,
	fetchRelatedRecords,
	useDataProvider,
	useListContext,
} from "react-admin";
import { ImportButton } from "react-admin-import-csv";

const fields = [
	"email",
	"wish-adopt",
	"age",
	"size",
	"city",
	"genre",
	"have-animal.dog",
	"have-animal.cat",
	"have-children",
	"i-live",
	"ready",
	"sport",
];

const QuizList: React.FC<{}> = (props) => {
	const ListActions = (props: any) => {
		const { exporter } = useListContext(props);
		const dataProvider = useDataProvider();

		return (
			<TopToolbar>
				<ExportButton
					exporter={(data) => {
						const dataMaped = data.map((data: any) =>
							fields.reduce((prev, curr) => {
								_.set(prev, curr, _.get(data, curr));
								return prev;
							}, {})
						);
						exporter &&
							exporter(
								dataMaped,
								fetchRelatedRecords(dataProvider),
								dataProvider,
								"quiz"
							);
					}}
					maxResults={10000}
					{...props}
				/>
				<ImportButton resource="quiz" />
			</TopToolbar>
		);
	};

	return (
		<List {...props} actions={<ListActions />}>
			<Datagrid rowClick="edit">
				{fields.map((name) => (
					<TextField source={name} label={`quiz.${name}`} />
				))}
			</Datagrid>
		</List>
	);
};

export default QuizList;
