import React, {  } from 'react';
import { TextInput, ReferenceInput, SelectInput, FormTab, TabbedForm, Datagrid, ReferenceManyField, TextField, useGetList, BooleanInput, ImageInput, ImageField, FormDataConsumer, useTranslate, FilterListItem, FilterList, FormGroupsProvider } from 'react-admin';

import { TabbedShowLayout, Tab } from '../../shared/components/tabs';
import AnimalList, { AnimalGrid } from '../animal/AnimalsList';
import {TextField as TextFieldMui, Box, Grid, Typography} from '@mui/material';
import _ from 'lodash';
interface RefugeFormProps {
  isEdit: boolean;
}

const RefugeForm : React.FC<RefugeFormProps>= (props) => {
  const translate = useTranslate();

  const AnimalsList = (props: any) => {
    const { data, total, isLoading, error } = useGetList('animal', {filter : {refugeId : props.refugeId, pageSize : 1000}, pagination : {page : 1, perPage : 1000}});
    return (
      <>      
        {/* <FilterList label="animal.filters.type" icon={<></>}>
          <FilterListItem label={"Adopté"} value={{adopted : true}} />
        </FilterList>   */}
        <AnimalGrid data={data} isLoading={isLoading}  />
        {/* <AnimalList refugeId={props.refugeId} /> */}
      </>
    )
  }

  const User = (props: any) => {
    const { data, total, isLoading, error } = useGetList('user', {filter : {refugeId : props.refugeId}});
    return (
      <>
        {
          data?.map((user: any) => {
            return (
              <Box>
                <Box marginBottom={'10px'} >
                  <TextFieldMui disabled value={user.firstname} />
                </Box>
                <Box marginBottom={'10px'}>
                  <TextFieldMui disabled value={user.lastname} />
                </Box>
                <Box>
                  <TextFieldMui disabled value={user.email} />
                </Box>
              </Box>
            )
          })
        }
      </>
    )
  }

  return (
    <TabbedForm warnWhenUnsavedChanges {...props}>
      <FormTab label='refuge.tabs.generic' >
      <Grid container width={{ xs: '100%', xl: 1000 }} spacing={2}>
        <Grid item xs={12} md={10}>
          <Typography variant="h6" gutterBottom>
              {translate(
                  'refuge.sections.infos'
              )}
          </Typography>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} flexDirection={'column'} display={'flex'} >
              <TextInput source='name' label='refuge.name' required />
              <TextInput source='siret' label='refuge.siret' required />
              <TextInput source='address' label='refuge.address' required />
              <TextInput source='phone' label='refuge.phone' required />
              <TextInput source='email' label='refuge.email' required />
              {/* <TextInput source='rna_hk' label='refuge.rna_hk' /> */}
              <TextInput source='donationLink' label='refuge.donationLink' />
              <TextInput source='website' label='refuge.website' />
            </Box>
            <Box>
              <TextInput source="description" label='refuge.description' multiline style={{width : 500}} sx={{textarea : {height : '250px !important'}}} />
            </Box>
          </Box>
          <Typography variant="h6" gutterBottom>
              {translate(
                  'refuge.sections.socials'
              )}
          </Typography>
          <Box display={'flex'} flexDirection={'column'} >
            <FormDataConsumer>
              {({ formData }) => {
                const { metaData } = formData;
                return metaData?.map(({key, value}: any, index: number) => {
                  if(['facebookLink', 'twitterLink', 'instagramLink', 'tiktokLink'].includes(key)){
                    return (
                      <Box>
                        <TextInput source={`metaData[${index}].value`} label={`${key}`} fullWidth />
                      </Box>
                    )
                  }
                })
              }}
            </FormDataConsumer>  
            <FormDataConsumer>
              {({ formData }) => {
                const { metaData } = formData;
                return ['facebookLink', 'twitterLink', 'instagramLink', 'tiktokLink']?.map((value: string, index: number) => {
                  const exist = _.some(metaData, {key : value});
                  if(!exist){
                    return (
                      <Box>
                        <TextInput source={`newMetaData.${value}`} label={`${value}`} />
                      </Box>
                    )
                  }
                })
              }}
            </FormDataConsumer> 
          </Box>
          <Typography variant="h6" gutterBottom>
              {translate(
                  'refuge.sections.other'
              )}
          </Typography>
          <Box>
            <BooleanInput source='hidePhone' label='refuge.hidePhone' />
            <BooleanInput source='hostFamily' label='refuge.hostFamily' />
          </Box>
        </Grid>
      </Grid>
      </FormTab>
      <FormTab label='refuge.tabs.photos'>
        <ImageInput source="logo" label="Image" accept="image/*" >
          <ImageField source="src" title="title" sx={{ img : {width : '250px !important', height : '250px !important'}}} />
        </ImageInput>
      </FormTab>
      {
        props.isEdit &&
        <FormTab label='refuge.tabs.user' >
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <User refugeId={formData.id} />
              )
            }}
          </FormDataConsumer>
        </FormTab>
      }
      {
        props.isEdit &&
        <FormTab label='refuge.tabs.animal' >
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <AnimalsList refugeId={formData.id} />
              )
            }}
          </FormDataConsumer>
        </FormTab>
      }
    </TabbedForm>
  );
};

export default RefugeForm;


      // metaData        : [{key: {type : 'String'}, value : {type : 'String'}}],
