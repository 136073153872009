import React, {  } from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

import BreedForm from './BreedForm';
import { Box } from '@material-ui/core';
import { langChoices } from '../../shared/utils/tools';

const BreedCreate : React.FC<{}>= props =>
  <Create {...props} redirect='list' >
    <SimpleForm warnWhenUnsavedChanges>
      <BreedForm />
    </SimpleForm>
  </Create>

export default BreedCreate;