import React, {  } from 'react';
import { Create, SimpleForm } from 'react-admin';

import FaqForm from './FaqForm';

const FaqCreate : React.FC<{}>= props =>
  <Create {...props} redirect={() => 'faq'} resource='post' >
    <FaqForm />
  </Create>

export default FaqCreate;