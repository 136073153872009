import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslate, MenuItemLink, useStoreContext, useAuthState, useStore } from 'react-admin';
import SubMenu from './SubMenu';

import { resources, isLeaf, isHidden, ResourcesDefinition, BranchType} from '../resources';
import createCanI from '../providers/cani';

let initialState : MenuState;
const loadInitialState = () => {
  if(!initialState) {
    try {
      initialState = JSON.parse(localStorage.getItem('menu-state') || '{}');
    }
    catch(e) {
      initialState = {};
    }
  }

  return initialState;
};

const Menu : React.FC<MenuProps>= ({ onMenuClick, dense }) => {
  const translate = useTranslate();
  const store = useStoreContext();
  const account = store.getItem('Account');
  const canI = createCanI(account || {roles : []});

  const [state, setState] = useState<MenuState>(loadInitialState());
  const handleToggle = (menu : string) => {
    setState(state => {
      const newState = { ...state, [menu]: !state[menu] };
      localStorage.setItem('menu-state', JSON.stringify(newState));
      return newState;
    });
  };

  const open : boolean = localStorage.getItem('RaStore.sidebar.open') === "true";
  
  const buildResourceTree = (resources : ResourcesDefinition, basePath = "resources") =>
  resources.map((node) => {
    const name = node.name;
      const nodeName = `${basePath}.${name}`;
      if (isHidden(node)) {return null;}
      if (isLeaf(node)) {

        return canI('list', node.name) ? (
          <MenuItemLink
            key={nodeName}
            to={`/${name}`}
            primaryText={translate(nodeName, {
                smart_count: 2,
            })}
            leftIcon={node.icon || <span>IC</span>}
            onClick={() => localStorage.setItem('RaStore.sidebar.open', "true")}
            sidebarIsOpen={open}
            dense={dense}
          />
        ) : null;
      }

      const subTree = buildResourceTree((node as BranchType).children, nodeName).filter(item => !!item);

      if (subTree && subTree.length) {
        return (
          <SubMenu
            key={nodeName}
            handleToggle={() => handleToggle(nodeName)}
            isOpen={state[nodeName]}
            sidebarIsOpen={open}
            name={nodeName}
            icon={node.icon || <span>"IC"</span>}
            dense={!!dense}
            >
              {subTree}
          </SubMenu>
        );
      }
    });

  return (
    <div>
      {
        buildResourceTree(resources)
      }
    </div>
  );  
}

interface MenuProps {
  onMenuClick ?: () => void;
  dense ?: boolean;
}

type MenuState = {
  [name : string] : boolean;
}

export default Menu;
