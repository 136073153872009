import React from 'react';
import { 
  TextInput, 
  ImageInput, 
  ImageField, 
  useGetList, 
  TabbedForm,
  FormTab,
  useTranslate,
  SelectInput,
  Toolbar,
  SaveButton,
  DeleteButton,
} from 'react-admin';
import { Grid, Box, Typography } from '@mui/material';
import _ from 'lodash';
import parse from 'html-react-parser';

import {
	RichTextInput,
	RichTextInputToolbar,
	LevelSelect,
	FormatButtons,
	ListButtons,
	LinkButtons,
	QuoteButtons,
	ClearButtons,
  ColorButtons,
  AlignmentButtons,
} from 'ra-input-rich-text';

import { ImageButtons } from './components/ImageButton';
import BlogImageDialog from './components/BlogImageDialog';
import BlogPicturesList from './components/BlogPicturesList';
import config from '../../providers/configprovider';
import { langChoices } from '../../shared/utils/tools';


interface PostFormProps {
  isEdit?: boolean; 
  data?: any;
}

const PostForm : React.FC<PostFormProps>= (props) => {
  const {data : listCriterias = []} = useGetList('criterias');
  const translate = useTranslate();

  const MyToolbar = (props: any) => (
    <Toolbar {...props} style={{width : "100%", display : 'flex', justifyContent : 'space-between'}} >
      <SaveButton />
      <DeleteButton 
        redirect='/faq'
      />
    </Toolbar>
  );

  const MyRichTextInput = ({ size, ...props }: any) => (
    <RichTextInput
      toolbar={
        <RichTextInputToolbar>
          <LevelSelect size={size} />
          <FormatButtons size={size} />
          <ColorButtons size={size} />
          <AlignmentButtons size={size} />
          <ListButtons size={size} />
          <LinkButtons size={size} />
          <BlogImageDialog 
            title={translate('questions.quizCreateTitle')}
            Layout={BlogPicturesList}
            // transform={(data: any) => ({...data, quizId : id})}
            // onChange={refresh}
            record={{id: ""}}
          />
          <QuoteButtons size={size} />
          <ClearButtons size={size} />
        </RichTextInputToolbar>
      }
      label="content"
      source="content"
      {...props}
    />
  );
  
  return (
    <TabbedForm warnWhenUnsavedChanges {...props} toolbar={<MyToolbar />}>
      <FormTab label='post.tabs.generic'>
        <div>
          <Grid container width={{ xs: '100%', xl: 800, md : 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <Box>
                    <TextInput defaultValue={'faq'} disabled source="type" label='post.type' fullWidth sx={{textarea : {height : '50px !important'}}} required />
                  </Box>
                  <Box>
                    <TextInput source="title" label='post.title' fullWidth required />
                  </Box>
                  <Box>
                    <RichTextInput 
                      label="post.content"
                      source='content'  
                      isRequired
                      toolbar={
                        <RichTextInputToolbar>
                          <LevelSelect size={'medium'} />
                          <FormatButtons size={'medium'} />
                          <ColorButtons size={'medium'} />
                          <AlignmentButtons size={'medium'} />
                          <ListButtons size={'medium'} />
                          <LinkButtons size={'medium'} />
                          <BlogImageDialog 
                            title={translate('post.addImage')}
                            Layout={BlogPicturesList}
                            // transform={(data: any) => ({...data, quizId : id})}
                            // onChange={refresh}
                            record={{id: ""}}
                          />
                          <QuoteButtons size={'medium'} />
                          <ClearButtons size={'medium'} />
                        </RichTextInputToolbar>
                      }
                    />
                  </Box>
                  <Box>
                    <SelectInput source='lang' choices={langChoices} label='animalType.lang' required />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </FormTab>
    </TabbedForm>
  );
};

export default PostForm;
