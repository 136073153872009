import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { 
  List, 
  Datagrid, 
  TextField, 
  ReferenceField, 
  Pagination, 
  useCreatePath,
  useListContext,
  RecordContextProvider
} from 'react-admin';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography
} from '@mui/material';
import { Photo } from '../../types';
import { Translate } from '@material-ui/icons';
import { useTranslate } from 'react-admin';

const BlogPicturesList : React.FC<{}>= props => {

  const PicturesGrid = ({data, isLoading}: {data?: Photo[], isLoading?: boolean}, ...props: any) => {
    const createPath = useCreatePath();
    const translate = useTranslate();
    const { data : listAnimals } = useListContext<Photo>();
    const {
      icon = <div></div>,
      label = '',
      scrollToTop = true,
      ...rest
  } = props;
  
    if (isLoading) {
        return null;
    }
    return (
      <Grid container spacing={2} sx={{ marginTop: '1em' }}>
        {(data || listAnimals)?.map((record) => (
          <RecordContextProvider key={record.id} value={record}>
            <Grid
              key={record.id}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={2}
              item
            >
              <Card sx={{height : 200}} >
                <CardMedia
                  image={record?.photo?.src}
                  sx={{ 
                    height: 200, 
                    backgroundSize: 'cover', 
                    backgroundPosition : '0% 20%',
                    display : 'flex',
                    alignItems : 'flex-end'  
                  }}
                >
                  <CardContent>
                    <Typography
                      align="center"
                      sx={{textDecoration : 'none', fontSize : 12, textAlign : 'left'}}
                      color={'white'}
                    >
                      {translate('blog-pictures.add')} {moment(record.createdAt).format('DD/MM/YYYY')}
                    </Typography>
                    <Typography
                      align="center"
                      sx={{
                        textDecoration : 'none', 
                        fontSize : 12, 
                        textOverflow : 'ellipsis', 
                        maxWidth : 200,
                        overflow : 'hidden',
                        whiteSpace : 'nowrap'
                      }}
                      color={'white'}
                    >
                      {record.name}
                    </Typography>
                  </CardContent>
                </CardMedia>
              </Card>
            </Grid>
          </RecordContextProvider>
        ))}
      </Grid>
    );
  };

  return (
    <List
      {...props}
      bulkActionButtons={false}
    >
      <PicturesGrid />
      {/* <Datagrid>
        <TextField source = 'name' />
      </Datagrid> */}
    </List>
  );
}

export default BlogPicturesList;