import React, {  } from 'react';
import { Create, SimpleForm } from 'react-admin';

import TeamForm from './TeamForm';

const TeamCreate : React.FC<{}>= props =>
  <Create {...props} redirect={() => 'team'} resource='post' >
    <TeamForm />
  </Create>

export default TeamCreate;