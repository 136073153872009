import React, {  } from 'react';
import { Create } from 'react-admin';

import AnimalForm from './AnimalForm';

const AnimalCreate : React.FC<{}>= props => {
  return (
    <Create {...props}>
      <AnimalForm />
    </Create>
  )
}

export default AnimalCreate;