import React, {  } from 'react';
import { Create, SimpleForm } from 'react-admin';

import PostForm from './PostForm';

const PostCreate : React.FC<{}>= props =>
  <Create {...props} redirect={() => 'blog'} resource='post' >
    <PostForm />
  </Create>

export default PostCreate;