import React from 'react';
import { Link } from 'react-router-dom';
import { 
  List, 
  Datagrid, 
  TextField, 
  ReferenceField, 
  Pagination, 
  useCreatePath,
  useListContext,
  RecordContextProvider,
  SortButton,
  TopToolbar,
  CreateButton,
  ExportButton,
  useGetList
} from 'react-admin';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography
} from '@mui/material';
import config from '../../providers/configprovider';
import { Post } from '../../types';

const TeamList : React.FC<{}>= props => {
  const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50, 100]} {...props} />;

  const PostGrid = ({data, isLoading}: {data?: Post[], isLoading?: boolean}, ...props: any) => {
    const createPath = useCreatePath();
    // const { data : posts, setFilters } = useListContext<Post>({filters : {type : 'team'}});
    const { data : posts  } = useGetList(
      'post',
      { 
          filter: { type: 'team' }
      }
  );
    const {
      icon = <div></div>,
      label = '',
      scrollToTop = true,
      ...rest
  } = props;
  
    if (isLoading) {
        return null;
    }
    return (
      <Grid container spacing={2} sx={{ marginTop: '1em' }}>
        {(data || posts)?.map((record) => (
          <RecordContextProvider key={record.id} value={record}>
            <Grid
              key={record.id}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={2}
              item
            >
              <Card sx={{height : 280}} >
                <Link
                  to={createPath({ type: 'edit', resource : 'team', id: record.id })}
                  style={{textDecoration : 'none'}}
                >
                  <CardMedia
                    image={`${config('UPLOAD_API_URL')}/blog-pictures/${record.thumbnail || record.main}/binary/medium`}
                    sx={{ height: 200, backgroundSize: 'cover', backgroundPosition : '0% 20%' }}
                  />
                  <CardContent sx={{ paddingBottom: '0.5em' }}>
                    <Typography
                      variant="h5"
                      component="h2"
                      align="center"
                      sx={{textDecoration : 'none', color : "#252f3a", fontSize : "16px"}}
                    >
                      {record.title}
                    </Typography>
                  </CardContent>
                </Link>
              </Card>
            </Grid>
          </RecordContextProvider>
        ))}
      </Grid>
    );
  };

  const ListActions = () => (
    <TopToolbar>
      <SortButton 
        fields={['createdAt']} 
        label="Trier par date de création" 
        resource='post' 
      />
      <CreateButton  resource='team'  />
      <ExportButton />
    </TopToolbar>
  );

  return (
    <List
      {...props}
      bulkActionButtons={false}
      pagination={<PostPagination />}
      actions={<ListActions />}
      filter={{type : 'team'}}
      resource='post'
    >
      <PostGrid />
    </List>
  );
}

export default TeamList;