import { Box } from '@material-ui/core';
import React, {  } from 'react';
import { Create, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { langChoices } from '../../shared/utils/tools';

const ColorCreate : React.FC<{}>= props =>
  <Create {...props} redirect='list'>
    <SimpleForm>
      <TextInput source="key" label='color.key' />
      <TextInput source="value" label='color.value' />
      <Box>
        <SelectInput source='lang' choices={langChoices} label='color.lang' />
      </Box>
    </SimpleForm>
  </Create>

export default ColorCreate;