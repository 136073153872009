import React from 'react';
import { Layout } from 'react-admin';
import Menu from './Menu';
import { makeStyles } from '@material-ui/core/styles';
import packageConfig from '../../package.json';
import CustomAppBar from './AppBar';

const useLayoutStyle = makeStyles(
  theme => ({
    version : {
      position : 'fixed',
      bottom   : 0,
      right    : 0,
      padding  : theme.spacing(1),
      color    : '#777',
      fontSize : '10px'
    }
  })
);

const CustomLayout : React.FC<any> = props => {
  const classes = useLayoutStyle();

  return (
    <Layout
      {...props}
      menu={Menu}
      appBar={CustomAppBar}
    >
      {props.children}
      <div className={classes.version}>
        v{packageConfig.version}
      </div>
    </Layout>
  );
};

export default CustomLayout;
