import React, {
    HtmlHTMLAttributes,
    ReactNode,
    useRef,
    useEffect,
    useMemo,
} from 'react';
import { Notification } from 'react-admin';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Card, Theme } from '@material-ui/core';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useNavigate } from 'react-router-dom';
import { useCheckAuth, TitleComponent } from 'ra-core';

// import logo from '../images/logo.png';

import defaultTheme from '../shared/styles/theme';
import DefaultLoginForm from './LoginForm';
import LoginForm from './LoginForm';

export interface LoginProps
    extends Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
    backgroundImage?: string;
    children?: ReactNode;
    classes?: object;
    className?: string;
    theme?: object;
    title?: TitleComponent;
}

const useStyles = makeStyles(
    (theme: Theme) => ({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: '1px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundColor : defaultTheme.palette.secondary.main
        },
        card: {
            minWidth: 300,
            width : '300px',
            marginTop: '6em',
        },
        avatar: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        icon: {
          height : '40px'
        },
    }),
    { name: 'RaLogin' }
);

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
const Login: React.FunctionComponent<LoginProps> = props => {
    const {
        theme,
        title,
        classes: classesOverride,
        className,
        children,
        backgroundImage,
        ...rest
    } = props;
    const containerRef = useRef<HTMLDivElement>();
    const classes = useStyles(props);
    const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
    // let backgroundImageLoaded = false;
    const checkAuth = useCheckAuth();
    const navigate = useNavigate();
    useEffect(() => {
        checkAuth({}, false)
            .then(() => {
                // already authenticated, redirect to the home page
                navigate('/');
            })
            .catch(() => {
                // not authenticated, stay on the login page
            });
    }, [checkAuth, navigate]);

    return (
        <ThemeProvider theme={muiTheme}>
            <div
                className={classnames(classes.main, className)}
                {...rest}
                // @ts-ignore
                ref={containerRef}
            >
                <Card className={classes.card}>
                    <div className={classes.avatar}>
                        {/* <img src={logo} className={classes.icon}/> */}
                    </div>
                    <LoginForm />
                </Card>
                <Notification />
            </div>
        </ThemeProvider>
    );
};


export default Login;

