import React, {  } from 'react';
import { TextInput, ReferenceInput, SelectInput, useGetList } from 'react-admin';

import { TabbedShowLayout, Tab } from '../../shared/components/tabs';
import { Box } from '@material-ui/core';
import { langChoices } from '../../shared/utils/tools';

const BreedForm : React.FC<{}>= (props) => {

  const AnimalsTypes = () => {
    const { data, isLoading } = useGetList('animal-type', { filter : { lang : 'fr' }});
    return (
      <SelectInput 
          label="animal.animalType"
          source="animalType"
          choices={data?.sort((a, b) => a.value < b.value ? -1 : 1)}
          optionText="value"
          optionValue="key"
          isLoading={isLoading}
      />
    );
  }

  return (
    <>
      <AnimalsTypes />
      <TextInput source="key" label='breed.key' />
      <TextInput source="value" label='breed.value' />
      <Box>
        <SelectInput source='lang' choices={langChoices} label='breed.lang' />
      </Box>
    </>
  );
};

export default BreedForm;