import React, {  } from 'react';
import { TextInput, ReferenceInput, SelectInput, TabbedForm, FormTab, BooleanInput, FormDataConsumer } from 'react-admin';

import { TabbedShowLayout, Tab } from '../../shared/components/tabs';

const UserForm : React.FC<{}>= (props) => {
  return (
    <TabbedForm warnWhenUnsavedChanges {...props}>
      <FormTab label='user.tabs.generic' >
        <TextInput source='firstname' label='user.firstname' />
        <TextInput source='lastname' label='user.lastname' />
        <TextInput disabled source='email' label='user.email' />
        <TextInput source='phone' label='user.phone' />
        <TextInput source='address' label='user.address' />
        <ReferenceInput source='refugeId' reference={'refuge'} label='user.refuge' >
          <SelectInput disabled optionText='name' sx={{width : '300px'}} />
        </ReferenceInput>
      </FormTab>
    </TabbedForm>
  );
};

export default UserForm;