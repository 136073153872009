import React from 'react';
import { 
  List, 
  Datagrid, 
  TextField, 
  ReferenceField, 
  Pagination, 
  useListContext,
  RecordContextProvider,
  FilterList,
  FilterListItem,
  TopToolbar,
  FilterLiveSearch,
  SortButton,
  CreateButton,
  ExportButton,
  DateField
} from 'react-admin';
import { User } from '../../types';
import { Grid, TableCell, TableRow, TableBody } from '@mui/material';

const MyUserDatagrid = () => {
  const { data : listUsers } = useListContext<User>();
  const listUsersFiltered = listUsers?.filter((user: User) => !user.refugeId);
  return (
    <Grid container spacing={2} sx={{ marginTop: '1em' }}>
      <TableRow>
      {(listUsersFiltered)?.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <TableCell>
              <div>{record.firstname}</div>
          </TableCell>
            <TableCell>
              <div>{record.lastname}</div>
            </TableCell>
        </RecordContextProvider>
      ))}
      </TableRow>
    </Grid>
  )
}

const ListActions = () => (
  <TopToolbar>
    <FilterLiveSearch source="q" />
    {/* <SortButton fields={['createdAt']} label="Trier par date de création" /> */}
    {/* <CreateButton /> */}
    <ExportButton />
  </TopToolbar>
);

const PostList : React.FC<{}>= props => {
  const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50, 100]} {...props} />;

  return (
    <List
      {...props}
      // pagination={<PostPagination />}
      filter={{ /* roles: 'user', */  f : "refugeId;nex"  }}
      actions={<ListActions />}
    >
      <Datagrid rowClick="edit">
        <TextField  source="firstname" label='user.firstname' />
        <TextField  source="lastname" label='user.lastname' />
        <TextField  source="email" label='user.email' />
        <DateField  source="createdAt" label='user.createdAt' />
      </Datagrid>
    </List>
  );
}

export default PostList;