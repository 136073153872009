import { DataProvider } from 'ra-core';

import config from './../configprovider';
import createDefaultDataProvider from './default';
import uploadsDataProvider from './uploads';
import { fetchUtils } from 'react-admin';
import uploadsPicturesProvider from './uploadsPictures';
import slugify from 'slugify';

const httpClient = fetchUtils.fetchJson;

export const modelDataProvider : DataProvider = createDefaultDataProvider(config('MODEL_API_URL'), 30);

export const RefugeDataProvider : DataProvider = {
  ...modelDataProvider,
  create : async (resource, params) => {
    if(params?.data?.logo.rawFile){
      const newParams = {
          data : {
            __file__ : params?.data?.logo
          }
        }
      const res = await uploadsPicturesProvider.create('refuge-pictures', newParams)
      const logoId = res.data.id;
      
      params.data.logo = logoId;
    }
    if(!Array.isArray(params.data.newMetaData) && params?.data?.newMetaData){
      const metaData = Object.entries(params.data.newMetaData)?.map(([key, value]) => ({key, value}));
      params.data.metaData = metaData;
    }
    return modelDataProvider.create('refuge', {...params.data, slug : slugify(`${params?.data?.name?.trim()}`, {
      replacement: '--',
      lower: true,
      remove: /[^\w\s-\.]/g,
      trim: true,
    }).replace(/\./g, '')
    .replace(/\(--\d+\)/, '')})
  },
  update : async (resource, params) => {
    if(params?.data?.logo?.rawFile){
      const newParams = {
          data : {
            __file__ : params?.data?.logo
          }
        }
      const res = await uploadsPicturesProvider.create('refuge-pictures', newParams);
      const logoId = res.data.id;
      params.data.logo = logoId;
    } else if(params?.data?.logo?.src){
      params.data.logo = params.data.logo.id;
    }
    if(!Array.isArray(params?.data?.newMetaData) && params?.data?.newMetaData){
      const metaData = Object.entries(params.data.newMetaData)?.map(([key, value]) => ({key, value}));
      params.data.metaData = [...params?.data?.metaData, ...metaData];
    }
    return modelDataProvider.update('refuge', params)
    
  },
  getOne : async (resource, params) =>
    httpClient(`${config('MODEL_API_URL')}/${resource}/${params.id}`, { user: { authenticated: true, token : `Bearer ${localStorage.getItem('token')}` }}).then(({ json } : any) => {
      return {
        data: {...json, logo : json.logo ? {id : json.logo, src : `${config('UPLOAD_API_URL')}/refuge-pictures/${json.logo}/binary/large` } : json.photos?.[0] ?  {id : json.photos?.[0], src : `${config('UPLOAD_API_URL')}/animal-pictures/${json.photos?.[0]}/binary/large` } : null},
        ...(0 ? { validUntil : new Date(Date.now() + 0*1000) } : {})
      }
  }),
}