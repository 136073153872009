import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { capitalize, sortBy, some } from 'lodash';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import moment from 'moment';
import VerifiedIcon from '@mui/icons-material/Verified';
import { 
  List, 
  useListContext,
  RecordContextProvider,
  FilterLiveSearch,
  FilterList,
  useGetList,
  FilterListItem,
  useCreatePath,
  useListFilterContext,
  SortButton,
  TopToolbar,
  CreateButton,
  ExportButton,
  SelectInput,
  useTheme,
} from 'react-admin';

import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Select,
  MenuItem,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  Box
} from '@mui/material';

import config from '../../providers/configprovider';
import { Animal } from '../../types/index';
import { ageChoices, genreChoices, sizeChoices } from '../../shared/utils/tools';


export const AnimalGrid = ({data, isLoading}: {data?: Animal[], isLoading?: boolean}, ...props: any) => {
  const createPath = useCreatePath();
  const theme = useTheme();
  const { data : listAnimals, ...rest } = useListContext<Animal>({filter : {lang : 'fr'}});
  const {
    icon = <div></div>,
    label = '',
    scrollToTop = true,
} = props;

  if (isLoading) {
      return null;
  }
  const mainTheme = '#252f3a';
  return (
    <>
      <div style={{padding : '10px 10px'}} ><span style={{color : mainTheme }}>{`${rest?.total || data?.length || 0}`}</span> résultats</div>
      <Grid container spacing={2} sx={{ marginTop: '1em' }}>
        {(data || listAnimals)?.map((record) => (
          <RecordContextProvider key={record.id} value={record}>
            <Grid
              key={record.id}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={2}
              item
            >
              <Card sx={{height : 280}} >
                <Link
                  to={createPath({ type: 'edit', resource : 'animal', id: record.id })}
                  style={{textDecoration : 'none'}}
                >
                  <CardMedia
                    image={`${config('UPLOAD_API_URL')}/animal-pictures/${record.photos[0]}/binary`}
                    sx={{ height: 200, backgroundSize: 'cover', backgroundPosition : '0% 20%' }}
                  />
                  <CardContent sx={{ marginTop : '-15px' }}>
                    <Typography
                      align="center"
                      sx={{textDecoration : 'none', color : "#252f3a", fontSize : "18px"}}
                    >
                      <div style={{textOverflow : 'ellipsis', whiteSpace : 'nowrap', overflow : 'hidden'}} >{record.name}</div>
                    </Typography>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} >
                      <Typography
                        sx={{textDecoration : 'none', color : "#252f3a", fontSize : "11px"}}
                      >
                        {genreChoices.find(({id}) => id === record.genre)?.name}{' '}
                        {ageChoices.find(({id}) => id === record.age)?.name}{' '}
                      </Typography>
                      {
                        record.adopted ? 
                        <Typography
                          align="left"
                          sx={{textDecoration : 'none', color : "#252f3a", fontSize : "10px", display : "flex", alignItems : 'center'}}
                        >
                          <VerifiedIcon fontSize='small' color='primary' sx={{marginRight : '2px'}} />
                          {'Adopté'}
                        </Typography> : null
                      }
                    </Box>
                    <Typography
                      sx={{textDecoration : 'none', color : "#252f3a", fontSize : "10px"}}
                    >
                      {'Né le'} {moment(record.birthday).format('DD/MM/YYYY')}
                    </Typography>
                    <Typography
                      align="right"
                      sx={{textDecoration : 'none', color : "#252f3a", fontSize : "10px"}}
                    >
                      {'Créé le'} {moment(record?.createdAt).format('DD/MM/YYYY')}
                    </Typography>
                  </CardContent>
                </Link>
              </Card>
            </Grid>
          </RecordContextProvider>
        ))}
      </Grid>
    </>
  );
};

const AnimalList : React.FC<{refugeId?: string}>= ({refugeId}) => {
  const listAnimal = useGetList('animal-type', {filter : {lang : 'fr'}});
  const {data : listCriterias = []} = useGetList('criterias');
    
    const MyMultiSelectInput = () => {
      const { filterValues, setFilters } = useListFilterContext();

      const addFilter = ({key}: any) => {
        setFilters({ ...filterValues, criterias : [...(filterValues?.criterias || []), {key, value : true}] }, null, true);
      };
    
      const removeFilter = (value: any) => {
        setFilters({...filterValues, criterias : [...filterValues?.criterias.filter(({key}: any) => key !== value.key)]}, null, false);
      };

      if(listCriterias.length){
        return (
          <>
          {
            listCriterias?.map(({value, key}) => {
              const isSelected = some(filterValues.criterias, {key, value : true});
              return (
                <StyledListItem
                  onClick={() => addFilter({key, value})}
                  selected={isSelected}
                  disablePadding
                >
                  <ListItemButton
                    disableGutters
                    className={FilterListItemClasses.listItemButton}
                  >
                    <ListItemText
                      primary={value}
                      className={FilterListItemClasses.listItemText}
                      data-selected={isSelected ? 'true' : 'false'}
                    />
                      {isSelected && (
                        <ListItemSecondaryAction
                          onClick={event => {
                              event.stopPropagation();
                              removeFilter({key})
                              // toggleFilter();
                          }}
                        >
                          <IconButton size="small">
                            <CancelIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItemButton>
                </StyledListItem>
              ) 
            })
          }
          </>
        )
      } else {
        return (
          <p></p>
        )
      }
  }

  const ListActions = () => (
    <TopToolbar>
      <SortButton fields={['createdAt']} label="Trier par date de création" />
      <CreateButton />
      <ExportButton />
    </TopToolbar>
  );

  const MySelectInput = () => {
    const { filterValues, setFilters } = useListFilterContext();
    const addFilter = (event: any) => {
        setFilters({ ...filterValues, animalTypeId : event?.target.value }, null, true);
    };
    return (
      <Select
      
        value={filterValues.animalTypeId}
        onChange={(value) => addFilter(value)}
        sx={{width : 225, height : 40}}
        defaultValue='Tous'
      >
        <MenuItem value=''>Tous</MenuItem>
        {sortBy(listAnimal.data, 'key')?.map(({value, id, key}) => <MenuItem key={key} value={key} >{capitalize(value)}</MenuItem>)}
      </Select>
    )
  }

  const PostFilterSidebar = () => {
    return (
      <Card sx={{ order: -1, mr: 2, mt: 8, minWidth: 270, height : 1300 }}>
        <CardContent>
          <FilterLiveSearch placeholder='Rechercher par nom' source='q' />
          <FilterList label="animal.filters.type" icon={<></>}>
            <MySelectInput />
          </FilterList>
          <FilterList label="animal.filters.genre" icon={<></>}>
            {
              genreChoices.map(({id, name}) => <FilterListItem label={name} value={{genre : id}} />)
            }
          </FilterList>
          <FilterList label="animal.filters.age" icon={<></>}>
            {
              ageChoices.map(({id, name}) => <FilterListItem label={name} value={{age : id}} />)
            }
          </FilterList>
          <FilterList label="animal.filters.size" icon={<></>}>
            {
              sizeChoices.map(({id, name}) => <FilterListItem label={name} value={{size : id}} />)
            }
          </FilterList>
          <FilterList label="animal.filters.criterias" icon={<></>}>
            <MyMultiSelectInput />
            {/* <FilterListItem label={"Vérifié"} value={{verified : true}} /> */}
            <FilterListItem label={"Vacciné"} value={{vaccined : true}} />
            <FilterListItem label={"Adopté"} value={{adopted : true}} />
          </FilterList>
          <div style={{marginTop : '30px'}} >
          </div>
        </CardContent>
      </Card>
    )
};

  return (
    <List
      bulkActionButtons={false}
      aside={<PostFilterSidebar />}
      perPage={25}
      actions={<ListActions />}
      filter={{refugeId}}
    >
      <AnimalGrid />
    </List>
  );
}

export default AnimalList;

const PREFIX = 'RaFilterListItem';

const FilterListItemClasses = {
  listItemButton: `${PREFIX}-listItemButton`,
  listItemText: `${PREFIX}-listItemText`,
};

const StyledListItem = styled(ListItem, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${FilterListItemClasses.listItemButton}`]: {
      paddingRight: '2em',
      paddingLeft: '2em',
  },
  [`& .${FilterListItemClasses.listItemText}`]: {
      margin: 0,
  },
}));