import { Box } from '@material-ui/core';
import React, {  } from 'react';
import { TextInput, ReferenceInput, SelectInput } from 'react-admin';
import { langChoices } from '../../shared/utils/tools';

const CriteriaForm : React.FC<{}>= (props) => {
  return (
    <>
      <TextInput source="key" label='criteria.key' />
      <TextInput source="value" label='criteria.value' />
      <Box>
        <SelectInput source='lang' choices={langChoices} label='criteria.lang' />
      </Box>
    </>
  );
};

export default CriteriaForm;