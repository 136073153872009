import { Box } from '@material-ui/core';
import React, {  } from 'react';
import { Edit, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { langChoices } from '../../shared/utils/tools';

const AnimalTypeEdit : React.FC<{}> = props =>
  <Edit mutationMode='undoable' {...props}>
    <SimpleForm>
      <TextInput disabled source="key" label='animalType.key' />
      <TextInput source="value" label='animalType.value' />
      <Box>
        <SelectInput source='lang' choices={langChoices} label='animalType.lang' />
      </Box>
    </SimpleForm>
  </Edit>;

export default AnimalTypeEdit;
