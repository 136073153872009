import { DataProvider } from 'ra-core';

import config from './../configprovider';
import createDefaultDataProvider from './default';
import uploadsDataProvider from './uploads';
import { accountsDataProvider } from './accounts';

export const modelDataProvider : DataProvider = createDefaultDataProvider(config('MODEL_API_URL'), 30);

export const UserDataProvider : DataProvider = {
  ...modelDataProvider,
  // getList : async (resource, params) => {
  //   const { filter } = params;
  //   let myAccountparams: any = {};
  //   let myUserparams: any = {};
  //   console.log('filter', filter)
  //   if(filter?.roles){
  //     for(const f in filter){
  //       if(f !== 'q'){
  //         myAccountparams[f] = filter[f];
  //       }
  //     }
  //     const {data : accounts, ...rest } = await accountsDataProvider.getList('accounts', {...params, filter : myAccountparams});
  //     console.log('rest', rest)
  //     const res = await modelDataProvider.getList('user', {...params, filter : {...params.filter, accountId : accounts?.map((account : any) => account.id).join(',')}});
  //     console.log('res', res)
  //     return {
  //       ...res,
  //       data : res.data.map((refuge : any) => {
  //         return {
  //           ...refuge,
  //           location : refuge.locationId
  //         }
  //       })
  //     }
  //   } else {
  //   }
  //   return modelDataProvider.getList('user', params);
    
  // },
  delete : async (resource, params) => {
    const res = await modelDataProvider.delete('user', params);
    return res;
  }
}