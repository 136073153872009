import { createTheme } from '@mui/material/styles';

export const DARK_BLUE  = "#252f3a";
export const LIGHT_BLUE = "#525DF4";
export const PINK       = "#F75252";


const customTheme = createTheme({
  palette: {
    primary: {main : LIGHT_BLUE},
    secondary: {main : DARK_BLUE},
    error: {main : PINK},
    contrastThreshold: 3,
    tonalOffset: 0.2,
  }
});

export default customTheme;
