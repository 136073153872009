import React, {  } from 'react';
import { Edit, TabbedForm } from 'react-admin';

import RefugeForm from './RefugeForm';

const RefugeEdit : React.FC<{}> = props =>
  <Edit mutationMode='undoable' {...props}>
    <RefugeForm isEdit={true} {...props}  />  
  </Edit>;

export default RefugeEdit;
