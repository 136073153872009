import React, {  } from 'react';
import { Create, SimpleForm } from 'react-admin';

import RefugeForm from './RefugeForm';

const RefugeCreate : React.FC<{}>= props =>
  <Create {...props} redirect='list' >
    <RefugeForm isEdit={false} />
  </Create>

export default RefugeCreate;