import * as React from 'react';
import { MenuItem, useMediaQuery, ListItemIcon, Divider, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Logout, UserMenu, useRedirect, useTranslate } from 'react-admin';
import { useStoreContext } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';

import { USER_SETTINGS } from '../shared/constants/routes';

const useLayoutStyle = makeStyles(
  theme => ({
    version : {
      position : 'fixed',
      bottom   : 0,
      right    : 0,
      padding  : theme.spacing(1),
      color    : '#777',
      fontSize : '10px'
    },
    menuItem: {
        color: theme.palette.text.secondary,
    },
    icon: {
      minWidth: theme.spacing(5)
    }
  })
);

const CustomUserMenu = (props : any) => {
  // const isXSmall = useMediaQuery((theme : Theme) =>
  //   theme?.breakpoints?.down('xs')
  // );

  const classes = useLayoutStyle();
  const redirect = useRedirect();
  const translate = useTranslate();

  const store = useStoreContext();
  const account = store.getItem('Account');
  const gotoSettings = () => redirect(USER_SETTINGS);

  return (
    <UserMenu key={account?.id} {...props}>
      <MenuItem
        className={classes.menuItem}
        >
        <ListItemIcon
          className={classes.icon}
          onClick={gotoSettings}
          >
          <SettingsIcon />
        </ListItemIcon>
        <div
          onClick={gotoSettings}
        >
          {translate('usermenu.profile')}
        </div>
      </MenuItem>
      <Divider />
      <Logout />
    </UserMenu>
  );
}

export default CustomUserMenu;
