import React, {  } from 'react';
import { Edit, SimpleForm } from 'react-admin';

import TeamForm from './TeamForm';

const TeamEdit : React.FC<{}>= props =>
  <Edit {...props} redirect={() => 'team'} resource='post' >
    <TeamForm />
  </Edit>

export default TeamEdit;