import React from 'react';
import { 
  List, 
  Datagrid, 
  TextField, 
  ReferenceField, 
  FilterLiveSearch, 
  FilterList, 
  FilterListItem, 
  useGetList,
  Pagination, 
  FormDataConsumer,
  TopToolbar,
  CreateButton,
  ExportButton,
  useListContext,
  useDataProvider,
  fetchRelatedRecords
} from 'react-admin';
import ListToolbar from './components/ListToolbar';
import { Card, CardContent } from '@mui/material';
import Pets from '@mui/icons-material/Pets';
import { ImportButton, ImportConfig } from "react-admin-import-csv";

const ListActions = (props: any) => {
  const {
    exporter
  } = useListContext(props);
  const dataProvider = useDataProvider();

  const config: ImportConfig = {
    postCommitCallback : (error: any) => console.log('error', error)
  }
  return (
  <TopToolbar>
    <CreateButton {...props} />
    <ExportButton 
      exporter={(data) => {
        const dataMaped = data.map(({key, value, lang, animalType}: any) => ({key, value, lang, animalType}));
        exporter &&
        exporter(
          dataMaped,
          fetchRelatedRecords(dataProvider),
          dataProvider,
          'breed'
      )
      }} 
      maxResults={10000} 
      {...props} 
    />
    <ImportButton {...config} />
  </TopToolbar>
)}

const BreedList : React.FC<{}>= props => {
  const PostFilterSidebar = () => {
    const listAnimal = useGetList('animal-type', {filter : {lang : 'fr'}});
    return (
      <Card sx={{ order: -1, mr: 2, mt: 8, width: 200, height : 800 }}>
          <CardContent>
              <FilterLiveSearch  />
              <FilterList label="breed.filter" icon={<Pets />}>
                {
                  listAnimal?.data?.sort((a,b) => a.value < b.value ? -1 : 1)?.map(({id, value, key}: any) => <FilterListItem key={id} label={value} value={{animalType : key}} />)
                }
              </FilterList>
          </CardContent>
      </Card>
    );
  }

  const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50, 100]} {...props} />;

  

  return (
    <List
      {...props}
      aside={<PostFilterSidebar />}
      pagination={<PostPagination />}
      actions={<ListActions />}
    >
      <Datagrid rowClick="edit">
        <TextField  source="animalType" label='breed.animalType' />
        <TextField  source="key" label='breed.key' />
        <TextField  source="value" label='breed.value' />
        <TextField  source="lang" label='breed.lang' />
        {/* <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <div></div>
            )
          }}
        </FormDataConsumer> */}
      </Datagrid>
    </List>
  );
}
{/* <ReferenceField reference='animal-type' source='animalType' label='breed.animalType'>
  <TextField source='value' label='breed.animalType' />
</ReferenceField> */}

export default BreedList;
