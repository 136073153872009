import React, {  } from 'react';
import { Edit } from 'react-admin';

import PartnerForm from './PartnerForm';

const PartnerEdit : React.FC<{}>= props =>
  <Edit {...props} redirect={() => 'partner'} resource='post' >
    <PartnerForm />
  </Edit>

export default PartnerEdit;