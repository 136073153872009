import React from 'react';
import { 
  List, 
  Datagrid, 
  TextField, 
  ReferenceField, 
  Pagination, 
  TopToolbar,
  FilterLiveSearch,
  SortButton,
  CreateButton,
  ExportButton,
  DateField
} from 'react-admin';

const ListActions = () => (
  <TopToolbar>
    <FilterLiveSearch source="q" />
    {/* <SortButton fields={['createdAt']} label="Trier par date de création" /> */}
    {/* <CreateButton /> */}
    <ExportButton />
  </TopToolbar>
);

const PostList : React.FC<{}>= props => {
  const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50, 100]} {...props} />;

  return (
    <List
      {...props}
      bulkActionButtons={false}
      pagination={<PostPagination />}
      filter={{ /* roles: 'refuge' */ f : "refugeId;ex" }}
      resource='user'
      actions={<ListActions />}
    >
      <Datagrid rowClick="edit" resource='association'>
        <TextField  source="firstname" label='user.firstname' />
        <TextField  source="lastname" label='user.lastname' />
        <TextField  source="email" label='user.email' />
        <DateField  source="createdAt" label='user.createdAt' />
      </Datagrid>
    </List>
  );
}

export default PostList;