import React, {  } from 'react';
import { TextInput, ReferenceInput, SelectInput, TabbedForm, FormTab, BooleanInput, FormDataConsumer } from 'react-admin';

import { TabbedShowLayout, Tab } from '../../shared/components/tabs';

const UserForm : React.FC<{}>= (props) => {
  return (
    <TabbedForm warnWhenUnsavedChanges {...props}>
      <FormTab label='user.tabs.generic' >
        <TextInput source='firstname' label='user.firstname' />
        <TextInput source='lastname' label='user.lastname' />
        <TextInput source='phone' label='user.phone' />
        <TextInput source='address' label='user.address' />
        <TextInput source='email' disabled label='user.address' />
        <BooleanInput source='hasCompleteQuiz' label='user.hasCompleteQuiz' />
      </FormTab>
      <FormTab label='user.tabs.quiz' >
        <FormDataConsumer>
          {({ formData }) => {
            const { metaData } = formData;
            return metaData?.map(({key, value}: any, index: number) => {
              if(key === 'wish-adopt'){
                return (
                  <TextInput source={`metaData[${index}].value`} label={`${key}`} />
                )
              } else {
                return (
                  <TextInput source={`metaData[${index}].value`} label={`${key}`} />
                )
              }
            })
          }}
        </FormDataConsumer>
      </FormTab>
      {/* <FormTab label='user.tabs.favoritesAnimals' >

      </FormTab>
      <FormTab label='user.tabs.favoritesRefuges' >

      </FormTab> */}
    </TabbedForm>
  );
};

export default UserForm;