import React, {  } from 'react';
import { Edit, SimpleForm } from 'react-admin';

import CriteriaForm from './CriteriaForm';

const CriteriaEdit : React.FC<{}> = props =>
  <Edit mutationMode='undoable' {...props}>
    <SimpleForm warnWhenUnsavedChanges>
      <CriteriaForm />
    </SimpleForm>
  </Edit>;

export default CriteriaEdit;
