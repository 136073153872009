// adoptSeltherFormList

import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Pagination,
    TopToolbar,
    SortButton,
    ExportButton,
    TextInput,
    FilterLiveSearch,
    DateField,
    UrlField
} from 'react-admin';

const PostFilters = () =>
    <TextInput label="Search" source="q" alwaysOn />

const ListActions = () => (
    <TopToolbar>
        <FilterLiveSearch source="q" />
        <SortButton fields={['createdAt']} label="Trier par date de création" />
        {/* <CreateButton /> */}
        <ExportButton />
    </TopToolbar>
);


const adoptSeltherFormList: React.FC<{}> = props => {
    const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50, 100]} {...props} />;
    return (
        <List
            {...props}
            bulkActionButtons={false}
            pagination={<PostPagination />}
            actions={<ListActions />}
        >
            <Datagrid rowClick="edit">
                <TextField source='email' label='Email' />
                <TextField source='lastname' label='lname' />
                <TextField source='firstname' label='First name' />
                <TextField source='phone' label='Phone' />
                <TextField source='clickedType' label='Button Clicked' />
                <TextField source='more' label='More Info' />
                <TextField source='refugeEmail' label='Refuge Email' />
                <TextField source='animalName' label='Animal Name' />
                <UrlField source='animalLink' label='Link' />
                <TextField source='lang' label='Language' />
                <DateField source='createdAt' label='Created At' />

            </Datagrid>
        </List>
    );
}

export default adoptSeltherFormList;