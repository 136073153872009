import React from 'react';
import { Edit } from 'react-admin';

import AnimalForm from './AnimalForm';

const AnimalEdit : React.FC<{}> = props => {

  return (
    <Edit mutationMode='pessimistic' {...props}>
      <AnimalForm isEdit />
    </Edit>
  )
}

export default AnimalEdit;
