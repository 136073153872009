import { RaRecord } from 'ra-core';
import jwt from 'jsonwebtoken';
import config from './configprovider';
import history from '../history';
import { Store } from 'ra-core';

import { apiObjectToFront, frontObjectToApi } from './services/accounts';
import canI from './cani';

import { FIRST_CONNECTION } from '../shared/constants/routes';
import { Account } from '../shared/types';
interface Credentials {
  username : string;
  password : string
};

interface Error {
  status : number
};

const redirectIfPristine = (account : any) => {
  if(account?.meta?.pristine === 'true') {
    history?.replace(FIRST_CONNECTION);
  }
}

/*
 Auth provider object
*/
const authProvider = {
    login: async ({username = '', password} : Credentials) => {
      const request = new Request(`${config('ACCOUNTS_API_URL')}/logins`, {
        method : 'POST',
        headers : new Headers({
          'Content-Type' : 'application/json',
        }),
        body : JSON.stringify({
          method : 'email',
          data : {
            email : username.trim().toLowerCase(),
            password
          }
        })
      });

      const response = await fetch(request);

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      const account = apiObjectToFront(await response.json());
      localStorage.setItem('token', account.token);
      localStorage.setItem("RaStore.Account", JSON.stringify(account));

      redirectIfPristine(account);
      return Promise.resolve();
    },

    logout: async () => {

      localStorage.clear();
    },

    checkAuth: async (force = false) => {
      let   account   = localStorage.getItem('Account');
      let accountId;
      if (account) {
        accountId = JSON.parse(account)?.id;
      }
      const token = localStorage.getItem('token');
      if (!token) {
        return Promise.reject();
      }

      if (token && (force || !accountId)) {
        const request = new Request(`${config('ACCOUNTS_API_URL')}/me`, {
          method : 'GET',
          headers : new Headers({
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${token}`
          })
        });

        const response = await fetch(request);

        if (response.status < 200 || response.status >= 300) {
          localStorage.clear();

          return Promise.reject();
        }

        account = apiObjectToFront(await response.json());
        localStorage.setItem("RaStore.Account", JSON.stringify(account));
      }
      if(account) {
        redirectIfPristine(account);
      }

      return Promise.resolve();
    },

    checkError: async (error : Error) => {
      const status = error.status;

      if (status === 401 || status === 403) {
        localStorage.removeItem('token');
        return Promise.reject();
      }

      return Promise.resolve();
    },

    getPermissions: async () => {
      const token = localStorage.getItem('token') || '';
      const tokenPayload = jwt.decode(token) as {roles ?: string[]};

      return canI({roles : tokenPayload?.roles || []});
    },

    changePassword : async (password : string) => {
      const account   = localStorage.getItem('RaStore.Account');
      let accountId;
      if(account) {
        accountId = JSON.parse(account)?.id;
      }

      const request = new Request(`${config('ACCOUNTS_API_URL')}/email-credentials/change`, {
        method : 'POST',
        headers : new Headers({
          'Content-Type' : 'application/json',
          'Authorization' : `Bearer ${localStorage.getItem('token')}`
        }),
        body : JSON.stringify({accountId, password})
      });

      const response = await fetch(request);

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
    },

    resetPassword : async (username : string) => {
      const request = new Request(`${config('ACCOUNTS_API_URL')}/email-credentials/lost`, {
        method : 'POST',
        headers : new Headers({
          'Content-Type' : 'application/json',
        }),
        body : JSON.stringify({email : username.trim().toLowerCase()})
      });

      const response = await fetch(request);

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
    },

    updateProfile : async (profile : RaRecord) => {
      const apiProfile = frontObjectToApi(profile);

      const request = new Request(`${config('ACCOUNTS_API_URL')}/me`, {
        method : 'PATCH',
        headers : new Headers({
          'Content-Type' : 'application/json',
          'Authorization' : `Bearer ${localStorage.getItem('token')}`
        }),
        body : JSON.stringify(apiProfile)
      });

      const response = await fetch(request);

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      return await response.json();
    },

    getIdentity : async () => {
      let   account   = localStorage.getItem('Account');
      if(account){
        const accountParsed = JSON.parse(account) as Account;
        const id = accountParsed.id;
        const fullName = (accountParsed?.meta?.firstName || '') + ' ' + (accountParsed?.meta?.lastName || '');
       
        return {id, fullName};
      } else {
        return Promise.reject()
      }
    }
};

export default authProvider;
