import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  RecordContextProvider,
  useGetList
} from 'react-admin';
import {
  Grid,
  Card,
  CardMedia,
  Pagination
} from '@mui/material';
import { Photo } from '../../../types';
import { useTiptapEditor } from 'ra-input-rich-text';
import configProvider from '../../../providers/configprovider';
import { Button } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

  const PicturesGrid:  React.FC<any> = (props: any) => {
    const editor = useTiptapEditor();
    const translate = useTranslate();
    const [page, setPage] = useState(1);
    const [selectedIds, setSelectedIds] = useState<{[key: string] : boolean}>({});
    const perPage = 12;
    const { data, total, isLoading, error, pageInfo } = useGetList(
      'blog-pictures',
      { 
          pagination: { page, perPage },
          sort: { field: 'createdAt', order: 'DESC' }
      }
    );
    const addImage = React.useCallback((id: string) => {
      if (id) {
        setSelectedIds((previous: any) => {
          if(previous?.[id]){
            return {...previous, [id] : false}
          } else {
            return {...previous, [id] : true}
          }
        })
      }
    }, [editor]);

    const handleSubmitImages = () => {
      Object.entries(selectedIds).forEach(([id, value]: any) => {
        if(value){
          editor.chain().focus().setImage({ src: `${configProvider('UPLOAD_API_URL')}/blog-pictures/${id}/binary/medium` }).run();
        }
      });
      props.handleHide();
    }
  
    if (isLoading) {
        return null;
    }

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    };

    return (
      <>
        <Grid container spacing={2} sx={{ marginTop: '0em', minHeight : 700 }}>
          {data?.map((record: Photo) => (
            <RecordContextProvider key={record.id} value={record}>
              <Grid
                key={record.id}
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                item
              >
                <Card sx={{height : 200, width : 250, cursor : 'pointer', outlineStyle : selectedIds?.[record.id] ? 'auto' : 'none', outlineColor : '#377be6'}} onClick={() => addImage(record.id)} >
                  <CardMedia
                    image={record?.photo?.src}
                    sx={{ height: 200, backgroundSize: 'cover', backgroundPosition : '0% 20%' }}
                  />
                </Card>
              </Grid>
            </RecordContextProvider>
          ))}
        </Grid>
        <Pagination style={{display : 'flex', justifyContent: 'center'}} count={Math.ceil((total || 1)/perPage)} onChange={handleChange} />
        <div style={{width : '100%', display : 'flex', justifyContent : 'flex-end'}} > 
        <Button onClick={handleSubmitImages} color="primary">
            {translate('post.insert')}
        </Button>
        </div>
      </>
    );
  };

export default PicturesGrid;