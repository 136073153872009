import React, {  } from 'react';
import { Edit } from 'react-admin';

import PostForm from './PostForm';



const PostEdit : React.FC<{}> = props =>
  <Edit mutationMode="pessimistic" {...props} redirect={'/blog'} resource='post'>
    <PostForm />
  </Edit>;

export default PostEdit;
