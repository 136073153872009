import React from 'react';
import { 
  TextInput, 
  ImageInput, 
  ImageField, 
  useGetList, 
  TabbedForm,
  FormTab,
  useTranslate,
  SelectInput,
  BooleanInput,
  DeleteButton,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { Grid, Box, Typography } from '@mui/material';
import _ from 'lodash';
import parse from 'html-react-parser';

import {
	RichTextInput,
	RichTextInputToolbar,
	LevelSelect,
	FormatButtons,
	ListButtons,
	LinkButtons,
	QuoteButtons,
	ClearButtons,
  ColorButtons,
  AlignmentButtons,
} from 'ra-input-rich-text';

import { ImageButtons } from './components/ImageButton';
import BlogImageDialog from './components/BlogImageDialog';
import BlogPicturesList from './components/BlogPicturesList';
import config from '../../providers/configprovider';
import { categories, langChoices } from '../../shared/utils/tools';


interface PostFormProps {
  isEdit?: boolean; 
  data?: any;
}

const PostForm : React.FC<PostFormProps>= (props) => {
  const translate = useTranslate();

  const MyToolbar = (props: any) => (
    <Toolbar {...props} style={{width : "100%", display : 'flex', justifyContent : 'space-between'}} >
      <SaveButton />
      <DeleteButton 
        redirect='/blog'
      />
    </Toolbar>
  );

  return (
    <TabbedForm warnWhenUnsavedChanges {...props} toolbar={<MyToolbar />}>
      <FormTab label='post.tabs.generic'>
        <div>
          <Grid container width={{ xs: '100%', xl: 800, md : 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <Box>
                    <TextInput defaultValue={'post'} disabled source="type" label='post.type' fullWidth sx={{textarea : {height : '50px !important'}}} required />
                  </Box>
                  <Box>
                    <TextInput source="title" label='post.title' fullWidth required />
                  </Box>
                  <Box>
                    <TextInput source="intro" label='post.intro' fullWidth multiline sx={{textarea : {height : '50px !important'}}} required />
                  </Box>
                  <Box>
                    <TextInput source="resume" label='post.resume' fullWidth multiline sx={{textarea : {height : '150px !important'}}} required />
                  </Box>
                  <Box>
                    <SelectInput choices={categories} source="category" label='post.category' fullWidth required  />
                  </Box>
                  {/* <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput source="slug" label='post.slug' fullWidth />
                  </Box> */}
                  <Box>
                    <SelectInput source='lang' choices={langChoices} label='animalType.lang' required />
                  </Box>
                  <Box>
                    <BooleanInput source='highlighted' label='post.highlighted' />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </FormTab>
      <FormTab label='post.tabs.photos'>
        <Box display='flex' flexDirection='row' >
          {/* <Box marginRight={30}>
            <Typography variant="h6" gutterBottom>
                {translate(
                    'post.photo.main'
                )}
            </Typography>
            <ImageInput source="main" label=" " accept="image/*" sx={{width : 300}} >
              <ImageField source="src" title="title" sx={{ img : {width : '250px !important', height : '250px !important'}}} />
            </ImageInput>
          </Box> */}
          <Box>
            <Typography variant="h6" gutterBottom>
              {translate(
                  'post.photo.thumbnail'
              )}
            </Typography>
            <ImageInput source="thumbnail" label=" " accept="image/*" sx={{width : 300}}>
              <ImageField source="src" title="title" sx={{ img : {width : '250px !important', height : '250px !important'}}} />
            </ImageInput>
          </Box>
        </Box>
      </FormTab>
      {/* <FormTab label='post.tabs.visual'>
        <Content />
      </FormTab> */}
      <FormTab label='post.tabs.editor'>
        <RichTextInput 
          isRequired
          label="post.content"
          source='content'  
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect size={'medium'} />
              <FormatButtons size={'medium'} />
              <ColorButtons size={'medium'} />
              <AlignmentButtons size={'medium'} />
              <ListButtons size={'medium'} />
              <LinkButtons size={'medium'} />
              <BlogImageDialog 
                title={translate('post.addImage')}
                Layout={BlogPicturesList}
                // transform={(data: any) => ({...data, quizId : id})}
                // onChange={refresh}
                record={{id: ""}}
              />
              <QuoteButtons size={'medium'} />
              <ClearButtons size={'medium'} />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
    </TabbedForm>
  );
};

export default PostForm;
