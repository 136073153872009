import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
	DeleteButton,
	FormTab,
	ImageField,
	ImageInput,
	SaveButton,
	SelectArrayInput,
	SelectInput,
	TabbedForm,
	TextInput,
	Toolbar,
	useGetList,
	useTranslate,
} from "react-admin";

import {
	AlignmentButtons,
	ClearButtons,
	ColorButtons,
	FormatButtons,
	LevelSelect,
	LinkButtons,
	ListButtons,
	QuoteButtons,
	RichTextInput,
	RichTextInputToolbar,
} from "ra-input-rich-text";

import { langChoices } from "../../shared/utils/tools";
import BlogImageDialog from "./components/BlogImageDialog";
import BlogPicturesList from "./components/BlogPicturesList";

interface PostFormProps {
	isEdit?: boolean;
	data?: any;
}

const AnimalsTypes = () => {
	const { data, isLoading } = useGetList("animal-type", {
		pagination: { page: 1, perPage: 10000 },
		filter: { lang: "fr" },
	});

	const choices = [
		{ key: "home", value: "Home" },
		...(data?.sort((a, b) => (a.key < b.key ? -1 : 1)) || []),
	];

	return (
		<SelectArrayInput
			label="partners.preference"
			source="preferredPages"
			choices={choices}
			optionText="value"
			optionValue="key"
			isLoading={isLoading}
			sx={{
				width: "100%",
			}}
		/>
	);
};

const PostForm: React.FC<PostFormProps> = (props) => {
	const { data: listCriterias = [] } = useGetList("criterias");
	const translate = useTranslate();

	const MyToolbar = (props: any) => (
		<Toolbar
			{...props}
			style={{
				width: "100%",
				display: "flex",
				justifyContent: "space-between",
			}}
		>
			<SaveButton />
			<DeleteButton redirect="/partner" />
		</Toolbar>
	);

	const MyRichTextInput = ({ size, ...props }: any) => (
		<RichTextInput
			toolbar={
				<RichTextInputToolbar>
					<LevelSelect size={size} />
					<FormatButtons size={size} />
					<ColorButtons size={size} />
					<AlignmentButtons size={size} />
					<ListButtons size={size} />
					<LinkButtons size={size} />
					<BlogImageDialog
						title={translate("questions.quizCreateTitle")}
						Layout={BlogPicturesList}
						// transform={(data: any) => ({...data, quizId : id})}
						// onChange={refresh}
						record={{ id: "" }}
					/>
					<QuoteButtons size={size} />
					<ClearButtons size={size} />
				</RichTextInputToolbar>
			}
			label="content"
			source="content"
			{...props}
		/>
	);

	return (
		<TabbedForm warnWhenUnsavedChanges {...props} toolbar={<MyToolbar />}>
			<FormTab label="post.tabs.generic">
				<div>
					<Grid container width={{ xs: "100%", xl: 800, md: 800 }} spacing={2}>
						<Grid item xs={12} md={8}>
							<Box display={{ xs: "block", sm: "flex" }}>
								<Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
									<Box>
										<TextInput
											defaultValue={"partners"}
											disabled
											source="type"
											label="post.type"
											fullWidth
											sx={{ textarea: { height: "50px !important" } }}
											required
										/>
									</Box>
									<Box>
										<TextInput
											source="title"
											label="post.title"
											fullWidth
											required
										/>
									</Box>
									<Box>
										<TextInput
											source="resume"
											label="post.link"
											fullWidth
											required
										/>
									</Box>
									<Box>
										<TextInput
											source="content"
											label="post.content"
											fullWidth
											multiline
											sx={{ textarea: { height: "150px !important" } }}
											required
										/>
									</Box>
									<Box>
										<SelectInput
											source="lang"
											choices={langChoices}
											label="animalType.lang"
											required
										/>
										<AnimalsTypes />
									</Box>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</div>
			</FormTab>
			<FormTab label="post.tabs.photos">
				<Box display="flex" flexDirection="row">
					<Box>
						<Typography variant="h6" gutterBottom>
							{translate("post.photo.thumbnail")}
						</Typography>
						<ImageInput
							source="thumbnail"
							label=" "
							accept="image/*"
							sx={{ width: 300 }}
						>
							<ImageField
								source="src"
								title="title"
								sx={{
									img: {
										width: "250px !important",
										height: "250px !important",
									},
								}}
							/>
						</ImageInput>
					</Box>
				</Box>
			</FormTab>
		</TabbedForm>
	);
};

export default PostForm;
