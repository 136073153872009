import React from 'react';
import { List, Datagrid, TextField, TopToolbar, CreateButton, ExportButton, useListContext, useDataProvider, fetchRelatedRecords } from 'react-admin';
import { ImportButton } from "react-admin-import-csv";

const AnimalTypesList : React.FC<{}>= props => {

  const ListActions = (props: any) => {
    const {
      exporter
    } = useListContext(props);
    const dataProvider = useDataProvider();

    return (
    <TopToolbar>
      <CreateButton {...props} />
      <ExportButton 
        exporter={(data) => {
          const dataMaped = data.map(({key, value, lang}: any) => ({key, value, lang}));
          exporter &&
          exporter(
            dataMaped,
            fetchRelatedRecords(dataProvider),
            dataProvider,
            'animal-type'
        )
        }} 
        maxResults={10000} 
        {...props} 
      />
      <ImportButton resource="animal-type" />
    </TopToolbar>
  )}

  

  return (
    <List
      {...props}
      actions={<ListActions />}
    >
      <Datagrid rowClick="edit">
        <TextField  source="key"   label='animalType.key' />
        <TextField  source="value" label='animalType.value' />
        <TextField  source="lang"  label='animalType.lang' />
      </Datagrid>
    </List>
  );
}

export default AnimalTypesList;
