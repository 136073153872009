import React from 'react';
import { AppBar } from 'react-admin';

import UserMenu from './UserMenu';

const CustomAppBar = (props : any) =>
  <AppBar
    {...props}
    userMenu={<UserMenu />}
    />;

export default CustomAppBar;
